<template>
  <vx-card no-body>
    <template #title>
      Permissions
    </template>

    <template #actions>
      <vx-button
        v-if="storeEditMode"
        :loading="updatePermissionsLoading"
        variant="primary"
        @click="handleSave"
      >
        Save
      </vx-button>
    </template>

    <b-card-body>
      <vx-table-simple
        :key="key"
        :items="items"
        :columns="columns"
        :filters="filters"
        :loading="getAllPermissionsLoading"
        :details-component="PermissionsDetails"
      />
    </b-card-body>
  </vx-card>
</template>

<script>
import VxCard from '@/components/VxCard'
import { permissions as permissionsResource, roles, useResource } from '@/services/resources'
import VxButton from '@/components/buttons/VxButton'
import usePermissions from '@/views/admins/role/usePermissions'
import {
  VxCellDetailsToggler,
  VxCellPermissionsRowCheckbox,
  VxTableSimple
} from '@/components/table'
import PermissionsDetails from '@/views/admins/role/PermissionsDetails'
import { computed, onBeforeMount, provide, ref, watch } from '@vue/composition-api'
import { forEach } from 'lodash'
import { filterTypes } from '@/services/table'
import { BCardBody } from 'bootstrap-vue'

export default {
  name: 'Permissions',
  components: {
    VxCellDetailsToggler,
    VxCellPermissionsRowCheckbox,
    VxTableSimple,
    VxButton,
    PermissionsDetails,
    VxCard,
    BCardBody
  },
  props: {
    role: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const roleId = computed(() => props.role.id)
    const key = ref(1)

    const {
      loading: getAllPermissionsLoading,
      callRequest: getAllPermissionsRequest
    } = useResource(permissionsResource.getAll)

    const {
      loading: updatePermissionsLoading,
      callRequest: updatePermissionsRequest,
      can: canUpdatePermissions
    } = useResource(roles.updatePermissions)

    const permissionsStore = usePermissions()
    provide('permissionsStore', permissionsStore)

    const {
      innerItems,
      editMode: storeEditMode,
      setEditMode,
      setInitialSelectedItems,
      formatItems,
      getColumns
    } = permissionsStore

    setEditMode(props.editMode && canUpdatePermissions)

    const items = ref([])
    const permissions = ref(null)

    const getPermissions = async () => {
      const [err, res] = await getAllPermissionsRequest()
      if (err) return

      permissions.value = res.data
    }

    const columns = [
      {
        key: 'details',
        label: '',
        component: VxCellDetailsToggler,
        tdClass: ['p-0', 'width-50'],
        tdAttr: (value, key, { groups }) => ({ show: !!groups })
      },
      {
        key: 'row-select',
        label: '',
        tdClass: ['p-0', 'width-50'],
        component: VxCellPermissionsRowCheckbox
      },
      ...getColumns()
    ]

    const filters = [
      {
        key: 'title',
        type: filterTypes.text
      }
    ]

    const frontToBackMapper = () => {
      const selectedPermissions = []
      forEach(innerItems, (value, id) => {
        if (value) selectedPermissions.push(id)
      })
      return selectedPermissions
    }

    const handleSave = async () => {
      const data = {
        permissions: frontToBackMapper()
      }
      await updatePermissionsRequest({ urlParams: { id: roleId.value }, data })
    }

    const initSelectedPermissions = () => {
      items.value = formatItems(permissions.value)
      setInitialSelectedItems(props.role.permissions || [])
    }

    watch(
      roleId,
      () => {
        initSelectedPermissions()
        key.value = key.value + 1
      }
    )

    onBeforeMount(async () => {
      await getPermissions()
      initSelectedPermissions()
    })

    return {
      key,

      items,
      columns,
      filters,
      getAllPermissionsLoading,

      PermissionsDetails,

      storeEditMode,
      updatePermissionsLoading,
      canUpdatePermissions,
      handleSave
    }
  }
}
</script>
