<template>
  <vx-form
    v-slot="{ loading, can }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <b-row cols="12">
      <b-col cols="12" md="6">
        <vx-input
          v-model="formValue.title"
          :rules="rules.title"
          name="title"
          label="Title"
        />
      </b-col>

      <b-col cols="12" md="6">
        <vx-input
          v-model="formValue.name"
          :rules="rules.name"
          name="name"
          label="Name"
        />
      </b-col>
    </b-row>

    <vx-textarea
      v-model="formValue.description"
      :rules="rules.description"
      name="description"
      label="Description"
    />

    <slot />

    <vx-button
      :loading="loading"
      :can="can"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { VxForm, VxInput, VxTextarea } from '@/components/form'
import { BCol, BRow } from 'bootstrap-vue'
import { VxButton } from '@/components/buttons'
import { reactive } from '@vue/composition-api'
import { passDataToResource, roles } from '@/services/resources'
import { apiDefaultValidators } from '@/services/form/validationService'
import { setValuesToForm } from '@/services/form'
import router, { buildRoute, path } from '@/router'

export default {
  name: 'RoleEditor',
  components: {
    BRow,
    BCol,
    VxForm,
    VxInput,
    VxTextarea,
    VxButton
  },
  props: {
    role: {
      type: Object,
      default: null
    },
    modalMode: Boolean
  },
  emits: ['submit'],
  setup ({ role, modalMode }, { emit }) {
    const isEdit = !!role?.id && !modalMode

    const formResource = isEdit
      ? passDataToResource(roles.update, {
        requestParams: { urlParams: { id: role.id } }
      })
      : roles.create

    const formValue = reactive({
      title: '',
      name: '',
      description: ''
    })

    const { maxTinyTextLength } = apiDefaultValidators
    const rules = {
      title: {
        required: true,
        min: 2,
        max: maxTinyTextLength
      },
      name: {
        required: true,
        min: 2,
        max: maxTinyTextLength
      },
      description: {
        required: true,
        max: 2000
      }
    }

    const handleSubmit = async ([err, res]) => {
      if (err) return

      if (!isEdit && res) {
        modalMode && role
          ? emit('submit', res)
          : await router.push(buildRoute(path.updateRole, { params: { id: res.data.id } }))
      }
    }

    if (role) {
      setValuesToForm(role, formValue)
    }

    return {
      formValue,
      formResource,
      rules,
      handleSubmit
    }
  }
}
</script>
