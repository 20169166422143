<template>
  <div>
    <b-row>
      <b-col>
        <vx-card>
          <role-editor :role="role" />
        </vx-card>
      </b-col>
    </b-row>
    <b-row v-if="role">
      <b-col>
        <permissions :role="role" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import RoleEditor from '@/views/admins/role/RoleEditor'
import Permissions from '@/views/admins/role/Permissions'
import VxCard from '@/components/VxCard'

export default {
  name: 'Role',
  components: {
    Permissions,
    RoleEditor,
    VxCard,
    BRow,
    BCol
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse: role }) {
    return { role }
  }
}
</script>
