import { flow, has, isObject, set as lodashSet, map, sortBy, forOwn } from 'lodash'
import { labelFormat } from '@/services/utils'
import { reactive, readonly, ref, set } from '@vue/composition-api'
import { VxCellPermissionsCheckbox } from '@/components/table'

const usePermissions = () => {
  const baseActions = {
    view: 'view',
    create: 'create',
    update: 'update',
    delete: 'delete'
  }

  const specificActions = {
    specific: 'specific'
  }

  const allActions = {
    ...baseActions,
    ...specificActions
  }

  // START: Edit mode toggle
  const editMode = ref(true)

  const setEditMode = (value) => {
    editMode.value = value
  }
  // END

  // START: Inner store
  const allPermissionsById = reactive({})
  const innerItems = reactive({})

  const setInitialSelectedItems = (selectedPermissions) => {
    selectedPermissions.forEach(({ id }) => {
      updateItem(id, true)
    })
  }

  const setNotSelectedItem = (id) => {
    updateItem(id, false)
  }

  const updateItem = (id, value) => {
    set(innerItems, id, value)
  }

  const updateManyItems = (ids = [], value) => {
    ids.forEach((id) => {
      updateItem(id, value)
    })
  }

  const updateAllItems = (value) => {
    forOwn(innerItems, (_, id) => {
      updateItem(id, value)
    })
  }
  // END

  const getIdsFromRow = (row) => {
    return Object.values(row).reduce((res, rowItem) => {
      if (Array.isArray(rowItem)) {
        rowItem.forEach((subRow) => {
          res.push(...getIdsFromRow(subRow))
        })
        return res
      }

      if (isObject(rowItem)) {
        res.push(rowItem.id)
      }

      return res
    }, [])
  }

  // START: Permissions formatters for rows
  const formatItems = (permissions) => {
    return flow([
      formatPermissionsWithSectionsByMap,
      formatSectionsToArray,
      sortSections
    ])(permissions)
  }

  const formatPermissionsWithSectionsByMap = (permissions) => {
    const separator = '.'

    const groupPath = (section, group, action) => {
      const groupPath = `${section}${separator}groups${separator}${group}`
      return action ? groupPath + separator + action : groupPath
    }

    const actionPath = (section, action) => {
      return section + separator + action
    }

    return permissions.reduce((preparedSections, permission) => {
      const { name, title, id } = permission
      set(allPermissionsById, id, permission)

      const nameChunks = name.split(separator)
      let section = null
      let groupName = null

      setNotSelectedItem(id)

      const setPermission = (path, value) => {
        lodashSet(preparedSections, path, value)
      }

      nameChunks.forEach((chunk, index, chunks) => {
        if (!section) {
          if (!preparedSections[chunk]) {
            preparedSections[chunk] = { title: chunk }
          }

          section = { title: chunk }
          return
        }

        const isLastIndex = index === chunks.length - 1
        const isBasePermission = !!baseActions[chunk]
        const { title: sectionName } = section

        // set base (view, create, update, delete) permission to section or group
        if (isLastIndex && isBasePermission) {
          const pathToPermission = groupName
            ? groupPath(sectionName, groupName, chunk)
            : actionPath(sectionName, chunk)
          setPermission(pathToPermission, { id, title })

          return
        }

        // find specific (not base) permissions
        if (isLastIndex && !isBasePermission && section) {
          const value = {
            title: chunk,
            [specificActions.specific]: { id, title }
          }
          setPermission(groupPath(sectionName, chunk), value)

          return
        }

        // find group
        if (!groupName && !isLastIndex && !isBasePermission) {
          groupName = chunk

          const pathToGroup = groupPath(sectionName, chunk)
          if (!has(preparedSections, pathToGroup)) {
            setPermission(pathToGroup, { title: chunk })
          }
        }
      })

      return preparedSections
    }, {})
  }

  const formatSectionsToArray = (sections) => {
    return map(sections, ({ groups, ...rest }) => {
      return {
        ...rest,
        ...(groups && { groups: Object.values(groups) })
      }
    })
  }

  const sortSections = (sections) => {
    return sortBy(sections, ({ groups }) => {
      let sortValue = 0
      sortValue += groups ? 1 : 0
      return sortValue
    })
  }
  // END

  // START: Columns
  const getColumns = (isDetailsColumns = false) => {
    const columns = isDetailsColumns ? allActions : baseActions
    // TODO: rewrite to Object.values
    return [
      {
        key: 'title',
        suppressMovable: true,
        tdClass: isDetailsColumns ? ['width-200'] : [],
        formatter: (value) => labelFormat(value)
      },
      ...map(columns, (permission) => ({
        key: permission,
        component: VxCellPermissionsCheckbox,
        tdAttr: { editMode }
      }))
    ]
  }
  // END

  return {
    allPermissionsById,

    innerItems: readonly(innerItems),
    setInitialSelectedItems,
    updateItem,
    updateManyItems,
    updateAllItems,

    editMode,
    setEditMode,

    formatItems,
    getColumns,

    getIdsFromRow
  }
}

export default usePermissions
